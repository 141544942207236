<template>
    <div class="vast-container">
        <div class="player">
            <a href="javascript:void(0)" @click="options.goBack()" v-if="options.goBack" class="dplayer-back">
                <span class="dplayer-icon-back">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000"
                         xml:space="preserve">
                <g><path d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z"/><path
                    d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z"/><path
                    d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z"/></g>
              </svg>
                </span>
            </a>

            <video ref="vastVideo" class="player__video viewer" autoplay :src="options.vast.url" playsinline></video>

            <button class="player__button toggle show paused" title="Toggle Play">
                <svg v-if="isPause" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 16 32">
                    <path
                        d="M15.552 15.168q0.448 0.32 0.448 0.832 0 0.448-0.448 0.768l-13.696 8.512q-0.768 0.512-1.312 0.192t-0.544-1.28v-16.448q0-0.96 0.544-1.28t1.312 0.192z"></path>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17 32">
                    <path
                        d="M14.080 4.8q2.88 0 2.88 2.048v18.24q0 2.112-2.88 2.112t-2.88-2.112v-18.24q0-2.048 2.88-2.048zM2.88 4.8q2.88 0 2.88 2.048v18.24q0 2.112-2.88 2.112t-2.88-2.112v-18.24q0-2.048 2.88-2.048z"></path>
                </svg>
            </button>

            <div class="player__controls">
                <div class="progress">
                    <div class="progress__filled"></div>
                </div>

                <div class="vast-info">
                    <span class="current">00:00</span> <span class="divider">/</span> <span
                    class="duration">00:00</span>
                    <span class="info-txt">Сурталчилгаа тоглуулж байна</span>
                </div>
            </div>
        </div>
        <!--        <video ref="vastVideo" onload="playVast" :src="options.vast.url" playsinline class="vast-video"/>-->

        <a v-if="options.vast.link != null" :href="options.vast.link" class="vast-link">{{ options.vast.title }}</a>

        <button ref="skipBtn" class="vast-skip-btn" :disabled="isSkipDisabled" @click="skipVast">
            Алгасах
            <span class="vast-counter" v-if="counter > 0">{{ counter }} сек </span>
            <span v-else>&#8594;</span>
        </button>
    </div>
</template>

<script>
export default {
    name: "ads.vue",
    props: ['options', 'skipVast'],
    data() {
        return {
            counter: 5,
            isSkipDisabled: true,
            isPause: true,
        }
    },

    mounted() {
        let vm = this;
        const player = document.querySelector('.player');
        const video = player.querySelector('.viewer');
        // const progress = player.querySelector('.progress');
        const progressBar = player.querySelector('.progress__filled');

        const toggle = player.querySelector('.toggle');

        const current = player.querySelector('.current');
        const duration = player.querySelector('.duration');


        player.addEventListener("mouseover", () => {
            toggle.classList.remove('hidden');
            toggle.classList.add('show');
        }, false);

        player.addEventListener("mouseleave", () => {
            const method = video.paused ? 'play' : 'pause';
            vm.isPause = video.paused;
            if (method == 'pause') {
                setTimeout(function () {
                    toggle.classList.remove('show');
                    toggle.classList.add('hidden');
                }, 1500);
            }
        }, false);


        // toggle play/pause
        function togglePlay() {
            const method = video.paused ? 'play' : 'pause';
            video[method]();

            if (method == 'pause') {
                toggle.classList.remove('hidden');
                toggle.classList.add('show');
            }
        }

        // Detect press of spacebar, toggle play
        // function detectKeypress(e) {
        //     if (e.keyCode == 32) {
        //         togglePlay();
        //     } else {
        //         return;
        //     }
        // }

        // Update button on play/pause
        function updateButton() {
            vm.isPause = video.paused;
            // let playIcon = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 16 32">
            //     <path d="M15.552 15.168q0.448 0.32 0.448 0.832 0 0.448-0.448 0.768l-13.696 8.512q-0.768 0.512-1.312 0.192t-0.544-1.28v-16.448q0-0.96 0.544-1.28t1.312 0.192z"></path>
            // </svg>`;
            //
            // let pauseIcon = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17 32">
            //     <path d="M14.080 4.8q2.88 0 2.88 2.048v18.24q0 2.112-2.88 2.112t-2.88-2.112v-18.24q0-2.048 2.88-2.048zM2.88 4.8q2.88 0 2.88 2.048v18.24q0 2.112-2.88 2.112t-2.88-2.112v-18.24q0-2.048 2.88-2.048z"></path>
            // </svg>`;
            //
            // const icon = this.paused ? playIcon : pauseIcon;
            // console.log(icon);
            //
            // toggle.innerHtml = icon;
        }

        function handleProgress() {
            const percent = (video.currentTime / video.duration) * 100;
            progressBar.style.flexBasis = `${percent}%`;

            if (vm.counter > 0) {
                vm.counter = parseInt(6 - video.currentTime);
            } else {
                vm.isSkipDisabled = false;
            }

        }

        // Get currnet time of video and append correct amount of 0s
        function currentTime() {
            var curmins = Math.floor(video.currentTime / 60);
            var cursecs = Math.floor(video.currentTime - curmins * 60);
            var durmins = Math.floor(video.duration / 60);
            var dursecs = Math.floor(video.duration - durmins * 60);

            if (cursecs < 10) {
                cursecs = "0" + cursecs;
            }
            if (dursecs < 10) {
                dursecs = "0" + dursecs;
            }
            if (curmins < 10) {
                curmins = "0" + curmins;
            }
            if (durmins < 10) {
                durmins = "0" + durmins;
            }
            current.innerHTML = curmins + ":" + cursecs;
            duration.innerHTML = durmins + ":" + dursecs;
        }


        // Click events
        video.addEventListener('click', togglePlay);
        toggle.addEventListener('click', togglePlay);

        // Keypress (Play/Pause)
        // window.addEventListener('keydown', detectKeypress);

        // Play/Pause events
        video.addEventListener('play', updateButton);
        video.addEventListener('pause', updateButton);

        video.addEventListener('timeupdate', currentTime);
        video.addEventListener('timeupdate', handleProgress);


        this.$refs.vastVideo.addEventListener('ended', () => {
            this.$refs.skipBtn.click();
            this.skipVast();
        })
    },

    // methods: {
    //     playVast() {
    //         console.log("playing vast");
    //         this.hasVast = true
    //
    //         this.timer = setInterval(() => {
    //             if (this.counter > 0) {
    //                 this.counter--;
    //             } else {
    //                 this.isSkipDisabled = false;
    //                 clearInterval(this.timer);
    //             }
    //         }, 1000)
    //
    //         this.$nextTick(() => {
    //             setTimeout(() => {
    //                 // this.$refs.vastVideo.muted = false;
    //                 this.$refs.vastVideo.click();
    //             }, 500);
    //
    //             // document.body.addEventListener("mousemove",  () => {
    //             //     this.$refs.vastVideo.play()
    //             // })
    //
    //             // setTimeout(() => {
    //             //     this.$refs.forcePlayBtn.trigger("click");
    //             //     const playedPromise = Promise.resolve(this.$refs.vastVideo.play());
    //             //     console.log(playedPromise);
    //             //
    //             //     playedPromise
    //             //         .catch(() => {
    //             //             this.$refs.vastVideo.pause();
    //             //         })
    //             //         .then(() => {
    //             //         });
    //             //
    //             // }, 1000)
    //
    //         })
    //     },
    // }
}
</script>
