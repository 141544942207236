import {createApp} from "vue";
import VueLazyload from "@jambonn/vue-lazyload";
import SimpleTypeahead from 'vue3-simple-typeahead';
import Toast, {useToast} from "vue-toastification";

//Prime
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Avatar from 'primevue/avatar';
import InputText from 'primevue/inputtext';
import Tooltip from 'primevue/tooltip';

import SpeedDial from 'primevue/speeddial';
import Chip from 'primevue/chip';
import ScrollPanel from 'primevue/scrollpanel';
import Divider from 'primevue/divider';
import 'primeicons/primeicons.css';
import SelectButton from 'primevue/selectbutton';
import ProgressSpinner from 'primevue/progressspinner';
import Knob from 'primevue/knob';
import OverlayPanel from 'primevue/overlaypanel';
import Badge from 'primevue/badge';
import Skeleton from 'primevue/skeleton';
import Checkbox from 'primevue/checkbox';
import ToastService from 'primevue/toastservice';
import Tag from 'primevue/tag';
import VideoBackground from 'vue-responsive-video-background-player'

//App section
import MaxPlayer from "../../../vue/player-seeroo/src/index.js"
import store from "./store/store";
import App from "./pages/App.vue";
import router from "./router";
import error from "./assets/error-seeroo.png";
import "./scss/style.scss";
import './assets/tailwind.css'

const app = createApp(App);

app.config.devtools = true
app.use(router)
app.use(store)
app.use(MaxPlayer)

//Prime
app.use(PrimeVue, {
    ripple: true,
    inputStyle: 'filled'
});
app.use(ToastService);

app.component('Dialog', Dialog);
app.component('Button', Button);
app.component('Menu', Menu);
app.component('Avatar', Avatar);
app.component('InputText', InputText);
app.directive('tooltip', Tooltip);
app.component('SpeedDial', SpeedDial);
app.component('Chip', Chip);
app.component('ScrollPanel', ScrollPanel);
app.component('Divider', Divider);
app.component('SelectButton', SelectButton);
app.component('Skeleton', Skeleton);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Knob', Knob);
app.component('OverlayPanel', OverlayPanel);
app.component('Tag', Tag);
app.component('Checkbox', Checkbox);
app.directive('Badge', Badge);
app.component('video-background', VideoBackground);

app.use(VueLazyload, {
    observer: true,
    preLoad: 1.3,
    error,
    attempt: 1,
})

app.use(SimpleTypeahead);

app.use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 10,
    newestOnTop: true,
    hideProgressBar: true,
    closeOnClick: true,
});

app.config.globalProperties.$toast = useToast()
// const fade = Vue.component('fade', {
//     template: '#page',
//     methods: {
//         enter(el, done) {
//             TweenMax.fromTo(el, 1, {
//                 autoAlpha: 0,
//                 scale: 1.5,
//             }, {
//                 autoAlpha: 1,
//                 scale: 1,
//                 transformOrigin: '50% 50%',
//                 ease: Power4.easeOut,
//                 onComplete: done
//             });
//         },
//         leave(el, done) {
//             TweenMax.fromTo(el, 1, {
//                 autoAlpha: 1,
//                 scale: 1,
//             }, {
//                 autoAlpha: 0,
//                 scale: 0.8,
//                 ease: Power4.easeOut,
//                 onComplete: done
//             });
//         }
//     }
// })

app.mount("#app");